import React from "react"
import marked from "marked"
import Link from "gatsby-link"
import Carousel from "react-elastic-carousel"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import ToggleBtn from "../components/toggle-btn"
import "../scss/modules/button-component.scss"
import "../scss/modules/sous-examen-component.scss"
import RdvBtn from "../components/rdv-btn"

const ExamenTemplate = ({ data }) => {
  return (
    <Layout>
      <Seo
        title={data.strapiSousexamen.titre}
        description={data.strapiSousexamen.Seo}
      />
      <div className={"sous-examen-component col-md-10"}>
        {data.strapiSousexamen.image.length === 1 ? (
          <div className="banner">
            <img
              src={
                "https://admin.radiologiecreteil.fr" +
                data.strapiSousexamen.image[0].url
              }
              alt={data.strapiSousexamen.titre}
            />
          </div>
        ) : data.strapiSousexamen.image.length > 0 ? (
          <Carousel itemsToShow={1} className={"carrousel"}>
            {data.strapiSousexamen.image.map((element, key) => {
              return (
                <div className={"item-carrousel"} key={key}>
                  <img
                    src={
                      "https://admin.radiologiecreteil.fr" + element.url
                    }
                    alt={data.strapiSousexamen.titre}
                    className={"img-carrousel"}
                  />
                </div>
              )
            })}
          </Carousel>
        ) : (
          <div></div>
        )}
        <div className={"content d-flex justify-content-between"}>
          <div className={"wrapperInfo"}>
            <div className={"title-content"}>
              <h2 className={"title-current-exam"}>
                {data.strapiExamens.titre}
              </h2>
              <span> - </span>
              <ToggleBtn
                currentExam={data.strapiExamens}
                currentSousExam={data.strapiSousexamen}
                title={true}
                relative={true}
              />
            </div>
            <div className={"content-text"}>
              <div className="wrapperDescription">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: marked(data.strapiSousexamen.description1),
                  }}
                ></div>
                <div className={"description d-flex flex-column"}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: marked(data.strapiSousexamen.description2),
                    }}
                  ></div>
                  <div className={"wrapper-button"}>
                    <RdvBtn data={data} title={"Prendre rendez-vous"}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={"other col-md-3"}>
            <h4>Nos autres examens</h4>
            <ul>
              {data.allStrapiExamens.edges
                .filter(item => item.node.titre !== data.strapiExamens.titre)
                .map((x, index) => {
                  return (
                    <Link to={`/Examens/${x.node.titre}`} key={index}>
                      <li>{x.node.titre}</li>
                    </Link>
                  )
                })}
            </ul>
          </div>
        </div>
        <div className={"circles"}>
          <span className={"circlesOne"}></span>
          <span className={"circlesTwo"}></span>
          <span className={"circlesThird"}></span>
          <span className={"circlesFourth"}></span>
          <span className={"circlesFifth"}></span>
        </div>
      </div>
    </Layout>
  )
}

export default ExamenTemplate

export const query = graphql`
  query SousExamenTemplate($id: String!, $titre: String!) {
    strapiSousexamen(id: { eq: $id }) {
      id
      titre
      description1
      description2
      image {
        url
      }
      examen {
        titre
      }
      SEO
    }
    allStrapiExamens {
      edges {
        node {
          titre
        }
      }
    }
    strapiExamens(titre: { eq: $titre }) {
      id
      titre
      description
      sousexamen {
        titre
        description1
        description2
        id
      }
    }
    allStrapiUrl {
      nodes {
        PrendreRendeVousSite1
        PrendreRendeVousSite2
      }
    }
  }
`
